import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from '../components/hero'
import Article from '../components/article'
import Wrapper from '../components/wrapper'
import Callout from '../components/callout'

const BlogPost = ({ data }) => {
  const post = data.markdownRemark;
  const { title, description, cover_image: mainImage } = post.frontmatter;

  return (
    <Layout title="Blog">
      <SEO title={title} description={description} image={mainImage} />
      <Hero title={title} description={description} />
      <Wrapper>
        <Article title={title} mainImage={mainImage}>
          <div dangerouslySetInnerHTML={{ __html: post.html }} />
        </Article>
      </Wrapper>
      <Callout title="Need help or leave feedback" description="" link="mailto:team@threadheap.com" />
    </Layout>
  )
}

export const pageQuery = graphql`
  query BlogPostByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        description
        tags
        cover_image
      }
    }
  }
`

export default BlogPost
